
.editor {
  height: 100%;
  width: 100%;
}

.code {
  height: 100%;
  width: 100%;
}

.sp-preview-container {
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 20px;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
}
.sp-preview-iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
.sp-stack {
  width: 100%;
  height: 100%;
  border: 0;
}
.sp-wrapper {
  width: 100%;
  height: 100%;
  border: 0;
}
.sp-preview-actions {
  visibility: hidden;
  height: 0px;
}
.sp-button {
  visibility : hidden;
}

.ace_tooltip_light {
  padding: 3px;
  border: 1px #c1c1c1 solid;
}

.ace_tooltip_dark {
  padding: 3px;
  border: 1px #484747 solid;
  background: #25282c;
  color: #c1c1c1;
}

.tabPanel {
  height: 100%;
  overflow: auto;
}
.preview {
  height: 100%
}
.ace_error_marker{position:absolute; border-bottom: 1px solid red}
.ace_warning_marker{position:absolute; border-bottom: 1px solid yellow}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.MuiPaper-outlined {
  border: 3px solid rgba(52, 66, 148,0.8) !important;
}